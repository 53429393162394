
import { Component } from 'vue-property-decorator';
import Editable from '@/mixins/Editable';
import { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';
import VWrapper from '@/components/shared/VWrapper.vue';
import StackedForm from '@/mixins/StackedForm';
import Notification from '@/mixins/Notification';
import { Category } from '@/interfaces/models/Category';
import { Subscription } from '@/interfaces/models/Subscription';
import EditSubscriptionLoader from '@/components/subscription/EditSubscriptionLoader.vue';
import SubscriptionForm from '@/components/subscription/SubscriptionForm.vue';
import { Article as ArticleModel, Article } from '@/interfaces/models/Article';
import AvailableHourForm from '@/components/subscription/AvailableHourForm.vue';
import { SubscriptionPrice } from '@/interfaces/models/SubscriptionPrice';
import SubscriptionPricesForm from '@/components/subscription/SubscriptionPricesForm.vue';
import Filter from '@/interfaces/api/Filter';
import { forEach } from 'lodash';

const venue = namespace('venue');
const foodcard = namespace('foodcard');

@Component({
  components: { VWrapper, EditSubscriptionLoader, SubscriptionForm, AvailableHourForm, SubscriptionPricesForm },
})
export default class Editsubscription extends mixins(Editable, StackedForm, Notification) {
  @venue.State('items') public venues!: Venue[];

  @foodcard.State('activeSubscription') public activeSubscription!: Subscription;
  @foodcard.State('categories') public categories!: Category[];

  @foodcard.Action('fetchCategoriesByVenue') public getCategories!: any;
  @foodcard.Action('updateSubscription') public update!: any;
  @foodcard.Action('uploadSubscriptionCategoryImage') public uploadImage!: any;
  @foodcard.Action('concatArticlesOfSubscription') public concatSearchArticles!: any;
  @foodcard.Action('resetArticlesOfSubscription') public resetArticles!: any;
  @foodcard.Action('setArticleOfSubscriptionFilter') public setArticleFilter!: any;
  @foodcard.Action('setArticleOfSubscription') public setArticle!: any;
  @foodcard.State('articleOfSubscriptionFilter') public articleFilter!: Filter;

  @foodcard.Action('storeSubscription') public store!: any;
  @foodcard.State('articlesOfSubscription') public items!: ArticleModel[];

  public $refs!: {
    form: InstanceType<typeof SubscriptionForm> & {
      getData: () => any;
      validate: () => Promise<boolean | boolean[]>;
    };
    availability: InstanceType<typeof SubscriptionForm> & {
      getData: () => any;
      validate: () => Promise<boolean | boolean[]>;
    };
  };

  public articleItems: Article[] = [];
  public tempArticleItems: Article[] = [];
  public pricesItems: SubscriptionPrice[] = [];
  public cacheVenue: string[] = [];

  public page: number = 1;
  public timeout: any;

  public onSearch(filter: { search: string }) {
    clearTimeout(this.timeout);
    const self = this;
    this.setArticleFilter({ ...this.articleFilter, ...filter });

    this.timeout = setTimeout(async () => {
      // return null if type event are focus, blur etc.
      if (filter.search !== null) {
        self.page = 1;
        if (self.cacheVenue.length) {
          this.resetArticles();
          self.articleItems = await self.getArticles(self.cacheVenue);
          self.articleItems = [...self.articleItems, ...self.tempArticleItems];
          this.setArticle(self.articleItems);
        }
      }
    }, 500);
  }

  public async loadMoreArticles() {
    if (this.cacheVenue.length) {
      this.articleItems = await this.getArticles(this.cacheVenue);
    }
  }

  public onArticleChange(articles: string[]): void {
    this.tempArticleItems = articles.reduce((acc: any, currentValue: string) => {
      const article = this.articleItems.find((item: Article) => item._id === currentValue);
      if (article) {
        acc.push(article);
      }

      return acc;
    }, []);
  }

  get title() {
    return this.editing ? 'subscription.edit' : 'subscription.create';
  }

  get subscription() {
    if (this.editing && this.activeSubscription) {
      return this.activeSubscription;
    }

    return null;
  }

  public async mounted() {
    this.resetArticles();
    this.setArticleFilter({});
    if (this.subscription) {
      this.initArticles();
      this.onArticleChange(this.$refs.form.getData().articles);
      const subscriptionVenues = this.subscription.venues ? this.subscription.venues : [];
      this.cacheVenue = [...this.cacheVenue, ...subscriptionVenues];
      if (this.editing && this.subscription.prices) {
        this.pricesItems = this.subscription.prices;
      }
      if (!this.editing || this.subscription.prices!.length < 1) {
        this.pricesItems = [
          {
            period: {
              factor: 0,
              unit: 'day',
            },
            price: '',
          },
        ];
      }
    }
  }

  public initArticles(): void {
    if (this.subscription && this.subscription.articles) {
      this.articleItems = [...this.subscription.articles];
      this.setArticle(this.subscription.articles);
    }
  }

  public async getArticles(venues: string[] = []): Promise<Article[]> {
    let articleItems: ArticleModel[] = [];

    for (const venueId of venues) {
      await this.concatSearchArticles({ venue: venueId, page: this.page });
      articleItems = [...this.items];
    }
    if (venues.length) {
      this.page = this.page + 1;
    }

    return articleItems;
  }

  public async updateSubscriptionCategory(venuesId: string[]) {
    this.page = 1;
    this.resetArticles();
    this.initArticles();
    this.cacheVenue = venuesId;
  }

  public addPrices() {
    this.pricesItems = [
      ...this.pricesItems,
      {
        period: {
          factor: 0,
          unit: 'day',
        },
        price: '',
      },
    ];
  }

  public deletePrices(index: number) {
    this.pricesItems.splice(index, 1);
  }

  public async save() {
    Promise.all(await this.validate()).then(async (res: any) => {
      if (this.isValid(res)) {
        const data: Partial<Subscription> = this.getMergedData();
        if (this.editing) {
          await this.update({ _id: this.activeSubscription._id, ...data });
        } else {
          await this.store(data);
        }

        if (!this.activeSubscription) {
          this.$router.push({ name: 'subscription.index' });
          return;
        }
        let formData: FormData;
        const uploadMeta: { _id: string } = { _id: this.activeSubscription._id };

        if (data.image && data.image instanceof File) {
          formData = new FormData();
          formData.append('image', data.image as Blob);
          await this.uploadImage({ ...uploadMeta, image: formData });
        }

        this.$router.push({ name: 'subscription.index' });
      } else {
        this.notifyError('notification.form');
      }
    });
  }

  protected getMergedData() {
    let priceFormData: SubscriptionPrice[] = [];
    for (let i = 0; i < this.pricesItems.length; i++) {
      // @ts-ignore
      priceFormData.push(this.$refs[`priceForm${i}`][0].getData());
    }
    priceFormData = priceFormData.filter((prices: SubscriptionPrice) => prices.price.length !== 0);

    return {
      ...this.$refs.form.getData(),
      ...this.$refs.availability.getData(),
      prices: priceFormData,
    };
  }
}
