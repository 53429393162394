
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import StackedForm from '../../mixins/StackedForm';
import VFormBuilder from '../shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import DisplayMode from '@/mixins/DisplayMode';
import DisplayIdentifier from '@/mixins/DisplayIdentifier';
import VOpeningHour from '@/components/shared/domain/VOpeningHour.vue';
import { Article } from '@/interfaces/models/Article';
import { SubscriptionCategory } from '@/interfaces/models/SubscriptionCategories';
import { namespace } from 'vuex-class';
import { SubscriptionPrice } from '@/interfaces/models/SubscriptionPrice';

const foodcard = namespace('foodcard');

@Component({
  components: { VOpeningHour, VFormBuilder },
})
export default class SubscriptionPricesForm extends mixins(StackedForm, DisplayMode, DisplayIdentifier) {
  @Prop({ type: Object }) public price!: SubscriptionPrice;

  @foodcard.Action('addSubscriptionToSubscriptionCategory') public addSubscription!: any;

  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any; getFilesToRemove: () => string[] };
    formSubscriptionCategory: InstanceType<typeof VFormBuilder> & { form: any; getFilesToRemove: () => string[] };
  };

  public unitItem = ['day', 'week', 'month', 'quarter', 'year'];

  get mergedInitialValues() {
    return {
      ...this.price,
      factor: this.price.period.factor,
      unit: this.price.period.unit,
    };
  }

  get items() {
    return [
      { name: 'price', type: InputType.Price, useTextarea: true, label: 'subscription.form.price', rules: 'required' },
      { name: 'factor', type: InputType.Price, useTextarea: true, label: 'subscription.form.factor' },
      {
        name: 'unit',
        type: InputType.Select,
        label: 'subscription.form.unit',
        items: this.unitItem,
      },
    ];
  }

  public getData() {
    const data: any = {
      period: {
        factor: this.$refs.form.form.factor,
        unit: this.$refs.form.form.unit,
      },
      price: this.$refs.form.form.price,
    };
    const filesToRemove: string[] = this.$refs.form.getFilesToRemove();
    return { ...data };
  }
}
