
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import StackedForm from '../../mixins/StackedForm';
import VFormBuilder from '../shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import DisplayMode from '@/mixins/DisplayMode';
import DisplayIdentifier from '@/mixins/DisplayIdentifier';
import VOpeningHour from '@/components/shared/domain/VOpeningHour.vue';
import { Article } from '@/interfaces/models/Article';
import { namespace } from 'vuex-class';
import { Subscription } from '@/interfaces/models/Subscription';
import SubscriptionPricesForm from './SubscriptionPricesForm.vue';
import { Venue } from '@/interfaces/models/Venue';
import CustomerGroup from '@/mixins/CustomerGroup';
import Countries from '@/mixins/Countries';

const foodcard = namespace('foodcard');

@Component({
  components: { VOpeningHour, VFormBuilder, SubscriptionPricesForm },
})
export default class SubscriptionForm extends mixins(
  StackedForm,
  DisplayMode,
  DisplayIdentifier,
  CustomerGroup,
  Countries,
) {
  @Prop({ type: Object }) public subscription!: Subscription;
  @Prop({ type: Array }) public articles!: Article[];
  @Prop({ type: Array }) public venues!: Venue[];

  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any; getFilesToRemove: () => string[] };
    formSubscriptionCategory: InstanceType<typeof VFormBuilder> & { form: any; getFilesToRemove: () => string[] };
  };

  public taxItem = [
    {
      text: '0.20',
      value: '0.20',
    },
    {
      text: '0.19',
      value: '0.19',
    },
    {
      text: '0.16',
      value: '0.16',
    },
    {
      text: '0.10',
      value: '0.10',
    },
    {
      text: '0.081',
      value: '0.081',
    },
    {
      text: '0.07',
      value: '0.07',
    },
    {
      text: '0.077',
      value: '0.077',
    },
    {
      text: '0.05',
      value: '0.05',
    },
    {
      text: '0.055',
      value: '0.055',
    },
    {
      text: '0.03',
      value: '0.03',
    },
    {
      text: '0.026',
      value: '0.026',
    },
    {
      text: '0.025',
      value: '0.025',
    },
    {
      text: this.$t('subscription.form.default'),
      value: '-1',
    },
  ];

  get items() {
    return [
      { name: 'name', type: InputType.Language, useTextarea: true, label: 'subscription.form.name', rules: 'required' },
      { name: 'description', type: InputType.Language, useTextarea: true, label: 'subscription.form.description' },
      { name: 'image', type: InputType.File, label: 'subscription.form.image', default: null, accept: 'image/*' },
      {
        name: 'useMasterFoodcard',
        type: InputType.Checkbox,
        label: 'subscription.form.useMasterFoodcard',
        default: false,
      },
      { name: 'maxPricePerOrder', type: InputType.Price, label: 'subscription.form.maxPricePerOrder' },
      { name: 'maxPricePerArticle', type: InputType.Price, label: 'subscription.form.maxPricePerArticle' },
      { name: 'maxArticlesPerOrder', type: InputType.Price, label: 'subscription.form.maxArticlesPerOrder' },
      { name: 'maxOrdersPerDay', type: InputType.Price, label: 'subscription.form.maxOrdersPerDay' },
      { name: 'minimumOrderOffset', type: InputType.Price, label: 'subscription.form.minimumOrderOffset' },
      { name: 'isActive', type: InputType.Checkbox, label: 'subscription.form.isActive', default: false },
      {
        name: 'automaticallyRenew',
        type: InputType.Checkbox,
        label: 'subscription.form.automaticallyRenew',
        default: false,
      },
      { name: 'tax', type: InputType.Select, label: 'subscription.form.tax', default: '-1', items: this.taxItem },
      {
        name: 'country',
        type: InputType.Select,
        label: 'venue.form.country',
        items: this.countries,
      },
      {
        name: 'venues',
        type: InputType.Autocomplete,
        label: 'subscription.form.venues',
        items: this.venuesItem,
        multiple: true,
        change: this.onVenueChange,
      },
      {
        name: 'articles',
        type: InputType.Autocomplete,
        label: 'subscription.form.articles',
        items: this.articlesItem,
        onSearch: this.onChangeSearch,
        loadMore: this.loadMore,
        change: this.onArticleChange,
        multiple: true,
      },
      {
        name: 'customerGroup',
        type: InputType.Select,
        label: 'venue.form.customerGroup',
        items: this.customerGroups,
      },
      {
        name: 'linkedCustomerGroup',
        type: InputType.Select,
        label: 'subscription.form.linkedCustomerGroup',
        items: this.customerGroups,
      },
    ];
  }

  public onChangeSearch(searchTerm: null | string) {
    this.$emit('search', { search: searchTerm });
  }

  public loadMore() {
    this.$emit('loadMore');
  }

  public onArticleChange(value: string[]) {
    this.$emit('onArticleChange', value);
  }

  get articlesItem() {
    return this.articles.map((article: Article) => {
      return {
        text: this.$options.filters!.localized(article.name),
        value: article._id,
      };
    });
  }

  get venuesItem() {
    return this.venues.map((venue: Venue) => {
      return {
        text: this.$options.filters!.localized(venue.name),
        value: venue._id,
      };
    });
  }

  public async onVenueChange(venuesId: string[]) {
    this.$emit('updateSubscriptionCategory', venuesId);
  }

  public getData() {
    const data: any = { ...this.$refs.form.form };
    const filesToRemove: string[] = this.$refs.form.getFilesToRemove();

    if (data.assets === null) {
      delete data.assets;
    }

    // @ts-ignore
    if (this.initialValues) {
      if (
        // @ts-ignore
        this.initialValues.image &&
        // @ts-ignore
        this.initialValues.image.thumb &&
        // @ts-ignore
        this.initialValues.image.thumb.length > 0 &&
        filesToRemove.indexOf('image') > -1
      ) {
        data.image = {
          thumb: '',
          medium: '',
          large: '',
          original: '',
        };
      }
    }

    return { ...data };
  }
}
